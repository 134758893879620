<template>
  <a
    href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbU5TYkdneGMwbHhkMkZJWVdGeU9VeG5NMjlrZG1jOVBTSXNJblpoYkhWbElqb2lTM1paTmxCVmFFUkNTRUo1ZGl0YVVIRnplSEYyZHowOUlpd2liV0ZqSWpvaU5tUXlZMkl4TUdKaU56VTFOR013TmprNU9XUm1OREV6TWpabU1tRm1NR0UwTnpRNFlqUXdaamd4WVdFNVptUTRZVGs1TWpnM01UTmlObVE0WW1KaE5pSXNJblJoWnlJNklpSjk="
    target="_blank"
  >
    <img
      src="../images/GCB_Seal.svg"
      loading="lazy"
      width="150"
      height="85"
      alt="License"
    />
  </a>
</template>
